import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlane } from '@fortawesome/free-solid-svg-icons';
import { faBluesky, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
// import logonav from '../assets/images/logo-navbar.png';
import logo_SAA25 from '../assets/images/logo_SAA25_transparent_wide.png';
//import logosocial from '../assets/images/logo-social.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <img src={logo_SAA25} alt="SAA 2025" className="footer-logo" />
                    {/* TODO: remove when sponsors ready */}
                    {/* 
                    <p>Sponsored by</p>
                    <div className="footer-logo-container">
                        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><img src={logosocial} alt="Sponsor1" className="conductor-logo" /></a>
                        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><img src={logosocial} alt="Sponsor1" className="conductor-logo" /></a>
                        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><img src={logosocial} alt="Sponsor1" className="conductor-logo" /></a>
                        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer"><img src={logosocial} alt="Sponsor1" className="conductor-logo" /></a>
                    </div>
                     */}
                </div>

                <div className="footer-section-container">
                    <div className="footer-section">
                        <h3>Resources</h3>
                        <ul>
                            <li><Link to="/Contact">Contact Us</Link></li>
                            <li><a href="https://ambulatory-assessment.org/" target="_blank" rel="noopener noreferrer">SAA Website</a></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>SAA 2025</h3>
                        <ul>
                            <li><Link to="/Program">Program</Link></li>
                            <li><Link to="/FAQ">FAQ</Link></li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <h3>Social media</h3>
                        <div className="social-icons">
                            <a href="https://x.com/SAA2025Leuven" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter} /></a>
                            <a href="https://bsky.app/profile/saa2025leuven.bsky.social" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faBluesky} /></a>
                            {/* <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="footer-bottom">
                <p>&copy;SAA 2025. All Rights Reserved</p>
            </div> */}
        </footer>
    );
};

export default Footer;